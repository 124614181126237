import $ from 'jquery';
import 'bootstrap';
import { tns } from 'tiny-slider';
import AOS from 'aos'
import 'add-to-calendar-button'

AOS.init({
  once: true
});

window.$ = $;

$(function () {
  const windowElement = $(window);
  const scrollUpElement = $('#scroll-up');
  const topBarElement = $('#topbar');

  scrollUpElement.hide(); // Hide scroll-to-top button initially

  windowElement.scroll(function () {
    const scrollTop = windowElement.scrollTop();

    // Hide or show the scroll-up element based on scroll position
    if (scrollTop > 768) {
      scrollUpElement.fadeIn();
    } else {
      scrollUpElement.fadeOut();
    }

    // Hide or show the topbar element based on scroll position
    if (scrollTop > 64) {
      topBarElement.addClass('hide-topbar');
    } else {
      topBarElement.removeClass('hide-topbar');
    }
  });

  scrollUpElement.click(function () {
    $('html, body').animate({ scrollTop: 0 }, 200);
  });
  

  if (typeof tns !== 'undefined') {
    // Initialize Tiny Slider on Page 1
    if ($("#latest-news").length) {
      const latestNews = tns({
        container: "#latest-news",
        controlsContainer: "#customize-controls",
        gutter: 30,
        swipeAngle: false,
        speed: 500,
        loop: true,
        autoplayButtonOutput: false,
        controls: true,
        arrowKeys: true,
        autoHeight: false,
        responsive: {
          375: {
            edgePadding: 20,
            gutter: 50,
            controls: false,
            items: 1
          },
          700: {
            gutter: 30,
            items: 2
          },
          900: {
            controls: true,
            items: 4
          }
        }
        // Rest of the options for Page 1
      });
    }

    // Initialize Tiny Slider on Page 2
    if ($(".partners-slider").length) {
      const partnerSlider = tns({
        container: ".partners-slider",
        items: 10,
        gutter: 20,
        slideBy: 1,
        controlsPosition: "bottom",
        navPosition: "bottom",
        mouseDrag: true,
        autoplay: true,
        autoplayButtonOutput: false,
        controlsContainer: "#custom-control",
        responsive: {
          0: {
            items: 1,
            nav: true
          },
          768: {
            items: 3,
            nav: true
          },
          1440: {
            items: 5,
            nav: false
          }
        }
        // Rest of the options for Page 2
      });
    }

    // Initialize Tiny Slider on Page 3
    if ($(".default-slider-new").length) {
      const eventWorkshopSliderNew = tns({
        container: ".default-slider-new",
        controlsContainer: "#customize-controls-event-workshops-new",
        gutter: 30,
        swipeAngle: true,
        speed: 500,
        loop: true,
        autoplayButtonOutput: false,
        controls: true,
        arrowKeys: true,
       
        lazyload: true,
        responsive: {
          375: {
            edgePadding: 20,
            gutter: 50,
            controls: true,
            items: 1
          },
          700: {
            gutter: 30,
            items: 2
          },
          900: {
            controls: true,
            items: 2
          },
          1400: {
            controls: true,
            items: 3
          },
           1750: {
            controls: true,
            items: 4        
          }
        }
        
        // Rest of the options for Page 3
      });
    }
    
    // Initialize Tiny Slider on Page 4
    if ($(".default-slider-past").length) {
      const eventWorkshopSliderPast = tns({
        container: ".default-slider-past",
        controlsContainer: "#customize-controls-event-workshops-past",
        gutter: 30,
        swipeAngle: true,
        speed: 500,
        loop: true,
        autoplayButtonOutput: false,
        controls: true,
        arrowKeys: true,
        lazyload: true,        
        responsive: {
          375: {
            edgePadding: 20,
            gutter: 50,
            controls: true,
            items: 1
          },
          700: {
            gutter: 30,
            items: 2
          },
          900: {
            controls: true,
            items: 2
          },
          1400: {
            controls: true,
            items: 3
          },
          1750: {
            controls: true,
            items: 4   
            
          }
        }
        // Rest of the options for Page 4
      });
    }

    if ($(".my-slider").length) {
      const newSlider = tns({
        container: '.my-slider',
          items: 1,
          slideBy: 'page',
          autoplayButton: false,
          navAsThumbnails: true,
          navContainer: "#customize-thumbnails",
          controls: false

       
      });
    }
    
  }

  // Add event listener to the navigation links using event delegation
  $(document).on('click', 'nav a', function (e) {
    let targetId = $(this).attr('data-anchor'); // Use data-anchor attribute instead of href

    // Check if the targetId is a valid anchor link
    if (targetId && targetId.charAt(0) === '#') {
      let targetElement = $(targetId);

      if (targetElement.length) {
        e.preventDefault();

        scrollToSection(targetElement, function () {
          // Callback function to handle any additional logic after scrolling
          console.log('Scrolling completed');
          showTopBar();
        });
      }
    }
  });

  // Add event listener to window scroll
  windowElement.scroll(function () {
    if (!isScrollingOrClicking()) {
      showTopBar();
    }
  });
});

// Function to scroll to the section smoothly
function scrollToSection(targetElement, callback) {
  let headerContainer = $('.header-container');
  let headerHeight = headerContainer.outerHeight();
  let targetOffset = targetElement.offset().top - headerHeight;
  let currentScrollTop = $(window).scrollTop();
  let animationDuration = 500; // Adjust the duration (in milliseconds) to make it faster or slower

  // Calculate the target offset relative to the current scroll position
  let scrollTo = targetOffset - currentScrollTop;

  hideTopBar();

  $('html, body').stop().animate(
    {
      scrollTop: '+=' + scrollTo // Scroll relative to the current position
    },
    animationDuration,
    function () {
      // Callback function executed after the scroll animation is completed
      if (typeof callback === 'function') {
        callback();
      }
      showTopBar(); // Show the topbar after scrolling is completed
    }
  );
}

// Function to check if scrolling or clicking is in progress
function isScrollingOrClicking() {
  return $('html, body').is(':animated');
}

// Function to hide the topbar element
function hideTopBar() {
  $('#topbar').hide();
}

// Function to show the topbar element
function showTopBar() {
  $('#topbar').show();
}


$(document).ready(function() {
  let appDivs = $('.elfsight-app-3dea1ebd-53df-4bf3-b592-83d79e91e69f, .elfsight-app-51ba662a-482d-48c7-81a9-abd6639003b9, .elfsight-app-ebdbc14d-f5b7-4970-aa0a-a28dcc12f434');
  let spinnerDiv = $('.spinner');

  function checkContent() {
    let content = appDivs.text().trim();

    if (content === '') {
      if (appDivs.hasClass('eapps-widget') && appDivs.hasClass('eapps-widget-show-toolbar')) {
        spinnerDiv.addClass('d-none'); // Hide the spinner
      } else {
        spinnerDiv.removeClass('d-none'); // Show the spinner
      }
    } else {
      spinnerDiv.addClass('d-none'); // Hide the spinner
    }
  }

  checkContent();

  let observer = new MutationObserver(checkContent);
  let observedElement = appDivs.parent()[0];

  if (observedElement instanceof Element) {
    observer.observe(observedElement, { childList: true, subtree: true });
  }
});

